<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('student_gno_simulation')"
		                :isFilter="false">
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('student_gno_simulation')"
		                      :isFilter="false">
		        </HeaderMobile>
	        </template>
            <h5 class="mb-3">{{ $t('student_information') }}</h5>
            <b-row v-if="student">
                <b-col cols="12" md="4" xl="3">
                    <b-form-group :label="$t('student_number')">
                        <div>
                            {{ getObjectValue(student,'student_number') }}
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4" xl="3">
                    <b-form-group :label="$t('name_surname')">
                        <div>
                            {{ getObjectValue(student,'name') }} {{ getObjectValue(student,'surname') }}
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4" xl="3">
                    <b-form-group :label="$t('program')">
                        <div>
                            {{ getLocaleText(student, 'program_name')}}
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4" xl="3">
                    <b-form-group :label="$t('cgpa')">
                        <div>
                            {{ getObjectValue(student,'cgpa') }}
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4" xl="3">
                    <b-form-group :label="$t('semester')">
                        <div>
                            {{ getObjectValue(student,'academic_year') }} / {{this.getLocaleText(student, 'semester_name')}}
                        </div>
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row class="mb-3">
                <b-col cols="12">
                    <div v-if="courses">
                        <table class="table table-bordered rounded-sm">
                            <thead>
                                <tr>
                                    <th class="width-150">{{toUpperCase('course_code')}}</th>
                                    <th>{{toUpperCase('course_name')}}</th>
                                    <th class="text-center width-100">{{toUpperCase('credit')}}</th>
                                    <th class="text-center width-100">{{toUpperCase('ects_credit')}}</th>
                                    <th class="text-center width-100">{{toUpperCase('letter_grade')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="course in courses">
                                    <td class="align-middle">{{ course.course_code }}</td>
                                    <td class="align-middle">{{ getLocaleText(course,'course_name')}}</td>
                                    <td class="text-center align-middle">{{ course.credit }}</td>
                                    <td class="text-center align-middle">{{ course.ects_credit }}</td>
                                    <td class="text-center align-middle">
                                        <b-form-select
                                            :options="grades[course.grades_system_id] ? grades[course.grades_system_id]:[]"
                                            value-field="id"
                                            text-field="grade"
                                            disabled-field="notEnabled"
                                            size="sm"
                                            v-model="course.grade_id"
                                        ></b-form-select>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot v-if="result">
                            <tr>
                                <td colspan="5">
                                    <b>{{$t('calculated_gpa')}}:</b> {{result.gpa}}
                                    <b>{{$t('calculated_cgpa')}}:</b> {{result.cgpa}}
                                    <b>{{$t('total_credits_attempted')}}:</b> {{result.total_credits_attempted}}
                                    <b>{{$t('total_credits_earned')}}:</b> {{result.total_credits_earned}}
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-3" v-if="courses">
                <b-col cols="12">
                    <button class="btn btn-primary" @click="calculate()">{{$t('calculate')}}</button>
                </b-col>
            </b-row>
        </app-layout>
    </div>
</template>

<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

    // Other
    import isset from "isset-php";

    import StudentCourseSimulationService from "@/services/StudentCourseSimulationService";

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile
        },
        metaInfo() {
            return {
                title: this.toUpperCase('student_gno_simulation')
            }
        },
        async mounted() {
            if(this.$route.params.id == undefined){
                let user = await this.getUser();
                if (isset(() => user.active_student_program.id)) {
                    this.studentProgramId = user.active_student_program.id
                }
            } else {
                this.studentProgramId = parseInt(this.$route.params.id);
            }
            this.get();
        },

        data() {
            return {
                studentProgramId: null,
                courses: null,
                grades: [],
                result:null,
                student:null
            }
        },
        methods: {
            getUser() {
                return this.$store.getters['auth/getUser'];
            },

            async get() {
                StudentCourseSimulationService.get(this.studentProgramId)
                              .then(response => {
                                  let data = response.data.data;
                                  this.courses = data.courses;
                                  this.grades  = data.grades;
                                  this.student = data.student;
                                  if(!this.courses){
                                      this.$toast.error(this.$t('there_is_any_course_in_active_semester'));
                                  }
                              }).catch(e=>{
                                  this.showErrors(e);
                })
            },

            async calculate() {
                if(this.checkPermission('studentcoursesimulation_update')){
                    let grades=this.courses.filter(item=>{
                        return item.grade_id>0;
                    }).map(item=>{
                        return {id: item.id, grade_id: item.grade_id};
                    });

                    if(grades.length==0){
                        return;
                    }
                    StudentCourseSimulationService.update(this.studentProgramId, {grades: grades})
                                                  .then(response => {
                                                      this.result = response.data.data;
                                                  }).catch(err=>this.showErrors(err))
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            },
        }
    };
</script>

